<template>
    <h1> Test</h1>
      <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Top 5 Elementos que mas datos ingresan a newrelic
        </button>
      </h2>
      <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <table class="table table-hover table-striped-columns">
              <thead>
              <tr>
              <th scope="col" class="table-info">ID</th>
              <th scope="col" class="table-info">BROWSER</th>
              <th scope="col" class="table-info">DeviceType</th>
              <th scope="col" class="table-info">minionPublicIp</th>
              </tr>
          </thead>
          <tbody v-for="res1 in results1" :key="res1">
          <tr>
          <th scope="row" class="table">{{ res1.id }}</th>
          <td class="table">{{ res1.browser }}</td>
          <td class="table">{{ res1.deviceType }}</td>
          <td class="table">{{ res1.minionPublicIp }}</td>
          </tr>
      </tbody>
      </table> 
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
          Top 5 Elementos que mas datos ingresan a newrelic
        </button>
      </h2>
      <div id="collapse2" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <table class="table table-hover table-striped-columns">
              <thead>
              <tr>
              <th scope="col" class="table-info">ID</th>
              <th scope="col" class="table-info">BROWSER</th>
              <th scope="col" class="table-info">DeviceType</th>
              <th scope="col" class="table-info">minionPublicIp</th>
              </tr>
          </thead>
          <tbody v-for="res2 in results2" :key="res2">
          <tr>
          <th scope="row" class="table">{{ res2.id }}</th>
          <td class="table">{{ res2.browser }}</td>
          <td class="table">{{ res2.deviceType }}</td>
          <td class="table">{{ res2.minionPublicIp }}</td>
          </tr>
      </tbody>
      </table> 
        </div>
      </div>
    </div>
  </div>
  <footer>Axo</footer>
  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'app',
    data (){
        return{
            results1: [],
            results2: []
        };
    },

        

    mounted() {
      axios.all([
        axios.get('https://bitter-waterfall-3b17.fpantojaz87.workers.dev/', {
            headers: { 'X-Custom-PSK': "mypresharedkey"}
        }),
        axios.get('https://test1234.franciscopruebas.shop', {
            headers: { 'X-Custom-PSK': "mypresharedkey"}
        })
      ])
      .then(axios.spread((respuesta1, respuesta2) => {
        this.results1 = respuesta1.data.data.actor.account.nrql.results,
        this.results2 = respuesta2.data.data.actor.account.nrql.results
      }))
    }
  
  }
  
  </script>